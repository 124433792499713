import React from "react";
// import ReactTypingEffect from "react-typing-effect";

const Header = ({ ...props }) => {
  return (
    <div className="pt-4 pb-5" {...props}>
      <img
        src="../../../assets-2019/logo.png"
        className="col-4 col-md-2 p-0 mb-5"
        alt=""
      />
      <h1 className="custom-height">
        Hello!{" "}I'm Lester Tan, a designer & developer.
        {/* <ReactTypingEffect
          text={[
            "My Name is Lester Tan.",
            "I’m Designer & Developer",
            "Welcome to my online portfolio"
          ]}
          eraseDelay={2000}
          typingDelay={1000}
          speed={100}
          cursorClassName="font-weight-light text-gray"
        /> */}
      </h1>

      <p>
        <a
          href="https://www.behance.net/tanwanjern"
          className="btn-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          Behance
        </a>{" "}
        -{" "}
        <a
          href="https://www.linkedin.com/in/tanwanjern/"
          className="btn-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkedln
        </a>{" "}
        -{" "}
        <a
          href="https://codepen.io/tanwanjern/"
          className="btn-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          Codepen
        </a>{" "}
        -{" "}
        <a
          href="https://github.com/tanwanjern?/"
          className="btn-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>{" "}
        -{" "}
        <a href="mailto:twanjern@gmail.com" className="btn-text">
          Email
        </a>
      </p>
    </div>
  );
};

export default Header;
