import * as React from "react"

const Divider = () => {

    return(
        <div className="border-top"></div>
    )
}

export default Divider;
