import * as React from "react"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

const Work = () => {

    const works = useStaticQuery(graphql`
        query Works {
            allWorksJson {
            edges {
                node {
                id
                jsonId
                title
                subtitle
                product
                date
                type
                hightlight_title
                hightlight_content
                about
                isCollection
                links {
                    src
                    type
                    text
                    icon
                }
                collection {
                    id
                    title
                    type
                    images {
                    caption
                    src {
                        childImageSharp {
                        gatsbyImageData
                        }
                    }
                    }
                    content
                    links {
                        icon
                        src
                        text
                        type
                    }
                }
                preview {
                    childImageSharp {
                    gatsbyImageData
                    }
                }
                images {
                    src {
                    childImageSharp {
                        gatsbyImageData
                        id
                    }
                    }
                    caption
                }
                }
            }
            }
        }      
    `) 

    const [modal, setModal] = useState(null);
    const handleModal = id => {
        setModal(id ? works.allWorksJson.edges.find(data => data.node.jsonId === id) : null);
        // setModal(id ? DatabaseProject.find(x => x.id === id) : null);
    };

    return(
        <div className="mt-4">
            <div className="mb-3">
                <h4 className="font-bold">Featured projects</h4>
            </div>

            <div className="row row-sm">
                {works.allWorksJson.edges.map(
                    (data, index) => {
                    return(
                        <div className="col-md-6 col-lg-4 mb-4" key={index}>
                            <div className="project-item">
                                <GatsbyImage image={data.node.preview.childImageSharp.gatsbyImageData} className="w-100 h-100" alt={data.node.title} draggable="false"></GatsbyImage>
                                <button type="button" className="link" onClick={() =>handleModal(data.node.jsonId)} aria-label="Project"></button>
                            </div>
                        </div>
                    )
                })}
            </div>

            <ProjectModal
                visible={!!modal}
                handleClose={() => handleModal(false)}
                item={modal}
            />
        </div>
    )
}

const ProjectModal = ({ visible, handleClose, item, ...props }) => {

    if (!item) {
        return null;
    }

    const SingleProject = () =>{
        return(
            <>
                <h3 className="project-title text-center mt-3">{item.node.title}</h3>
                <p className="project-subtitle text-center">{item.node.subtitle}</p>

                <div className="project-shot mb-4">
                    <GatsbyImage image={item.node.images[0].src.childImageSharp.gatsbyImageData} className="w-100 h-100" alt={item.node.images[0].caption} draggable="false"></GatsbyImage>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <h5 className="project-title mb-1">{item.node.hightlight_title}</h5>
                    </div>
                    <div className="col-md">
                        <div className="project-body" dangerouslySetInnerHTML={{ __html: item.node.hightlight_content }} />
                    </div>
                </div>
                
                <hr/>

                <div className="row mb-2">
                    <div className="col-md-auto col-lg-4 mb-2">
                        <p className="subtitle mb-0">Project Type</p>
                        <h6 className="font-bold tracking-tide">{item.node.type}</h6>
                    </div>
                    {item.node.date ?
                        <div className="col-md-auto col-lg-4 mb-2">
                            <p className="subtitle mb-0">Date</p>
                            <h6 className="font-bold tracking-tide">{item.node.date}</h6>
                        </div>
                        :null
                    }
                    <div className="col-md-auto col-lg-4 mb-2">
                        <p className="subtitle mb-0">Product</p>
                        <h6 className="font-bold tracking-tide">{item.node.product}</h6>
                    </div>
                </div>
                <div className="row no-gutters">
                    {item.node.links.map((link, index) => {
                        return (
                            <div className="col-md-auto mr-2 mb-2" key={index}>
                                <a
                                    href={link.src}
                                    className={link.type}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className={link.icon} /> {link.text}
                                </a> 
                            </div>
                        );
                    })}
                </div>

                <hr className="mb-4"/>

                {item.node.about ?
                    <>
                        <h5 className="project-title mb-1">About the project</h5>
                        <div className="project-body">
                            <p>{item.about}</p>
                        </div>
                    </>
                    : null
                }
            </>
        )
    }

    const Collection = () => {
        return(
            <>
                <p className="text-blue-400 font-medium mt-5 mb-0">Collection</p>
                <h3 className="project-title">{item.node.title}</h3>
                <p className="project-subtitle mb-5">{item.node.subtitle}</p>

                {item.node.collection.map(
                    (p, i) => {
                    return (
                        <div key={i}>
                            <hr/>
                            <div className="row align-items-center">
                                <div className="col-md-5 mb-3">
                                    <h5 className="project-title">{p.title}</h5>
                                    <p className="text-sm text-secondary">{p.type}</p>
                                    <div className="project-body-sm mb-4" dangerouslySetInnerHTML={{ __html: p.content }} />

                                    {p.links.map((link, index) => {
                                        return (
                                            <div className="mb-2" key={index}>
                                                <a
                                                    href={link.src}
                                                    className={link.type}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className={link.icon} /> {link.text}
                                                </a> 
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="col-md mb-3">
                                    <div className="project-shot">
                                        <GatsbyImage image={p.images[0].src.childImageSharp.gatsbyImageData} className="w-100 h-100" alt={p.images[0].caption} draggable="false"></GatsbyImage>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    }
                )}
            </>
        )
    }

    return(
        <Modal show={visible} onHide={handleClose} size="lg" className="modal-2021">
            <button type="button" className="close" onClick={handleClose}>
                <i className="fas fa-times"></i>
            </button>
            <Modal.Body className="text-dark py-5">
                {
                    item.node.isCollection ?
                        <Collection/>
                    :
                        <SingleProject/>
                }
                
                <div className="mt-5">
                    <div className="d-flex justify-content-center mb-2">
                        <StaticImage src={'../images/assets-2021/icon-locked.png'} width={70} height={70} alt={"locked"} draggable="false"></StaticImage>
                    </div>
                    <p className="font-medium tracking-tide text-center text-sm">Looking for more? <a href="mailto:twanjern@gmail.com" className="text-muted">Contact me.</a></p>
                </div>
                
            </Modal.Body>
        </Modal>
    )
}

export default Work;