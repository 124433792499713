exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archived-2019-components-footer-js": () => import("./../../../src/pages/archived/2019/components/Footer.js" /* webpackChunkName: "component---src-pages-archived-2019-components-footer-js" */),
  "component---src-pages-archived-2019-components-header-js": () => import("./../../../src/pages/archived/2019/components/Header.js" /* webpackChunkName: "component---src-pages-archived-2019-components-header-js" */),
  "component---src-pages-archived-2019-components-portfolio-button-js": () => import("./../../../src/pages/archived/2019/components/PortfolioButton.js" /* webpackChunkName: "component---src-pages-archived-2019-components-portfolio-button-js" */),
  "component---src-pages-archived-2019-components-portfolio-card-js": () => import("./../../../src/pages/archived/2019/components/PortfolioCard.js" /* webpackChunkName: "component---src-pages-archived-2019-components-portfolio-card-js" */),
  "component---src-pages-archived-2019-components-portfolio-carousel-js": () => import("./../../../src/pages/archived/2019/components/PortfolioCarousel.js" /* webpackChunkName: "component---src-pages-archived-2019-components-portfolio-carousel-js" */),
  "component---src-pages-archived-2019-components-portfolio-modal-js": () => import("./../../../src/pages/archived/2019/components/PortfolioModal.js" /* webpackChunkName: "component---src-pages-archived-2019-components-portfolio-modal-js" */),
  "component---src-pages-archived-2019-index-js": () => import("./../../../src/pages/archived/2019/index.js" /* webpackChunkName: "component---src-pages-archived-2019-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

