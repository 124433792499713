import * as React from "react"

const About = () => {

    return(
		<div className="">
			<div className="container">
				<div className="py-5">
					<span className="halo wave" role="img" aria-label="halo">
						👋
					</span>
					<p className="bio-text mt-3">
						Product designer & frontend engineer with 6+ years of experience in a variety of roles, including UI/UX design, frontend development, mobile app development and project management.
						<br/><br/>
						Recently I've been working on a startup that re-imagine the definition of digital theatre to promote immersion and create memorable experiences for audiences around the world.
					</p>
					<hr className="my-4 border-white"/>
					<div className="row pt-4 stack">
						<div className="col-md-4 mb-4">
							<h3 className="subtitle mb-2">Design Stack</h3>
							<h6>Adobe Photoshop</h6>
							<h6>Adobe Illustrator</h6>
							<h6>Sketch</h6>
							<h6>Figma</h6>
							<h6>InVision Studio</h6>
							<div className="tools-icon position-relative ">
								<span className="jumping-dot">
									<span className="dot dot-1"></span>
									<span className="dot dot-2"></span>
									<span className="dot dot-3"></span>
								</span>
							</div>
						</div>
						<div className="col-md-4 mb-4">
							<h3 className="subtitle mb-2">Tech Stack</h3>
							<h6>React JS &#38; React Native</h6>
							<h6>Laravel PHP</h6>
							<h6>Tailwind &#38; Bootstrap CSS</h6>
							<h6>Statamic &#38; Wordpress CMS</h6>
							<h6>Gatsby JS &#38; Next JS</h6>
							<h6>Firebase</h6>
							<h6>Petite Vue</h6>
							<h6>Shopify &#38; Liquid</h6>
							<h6>Java &#38; Swift</h6>
							<h6>Expression Engine CMS</h6>
							<div className="tools-icon position-relative ">
								<span className="jumping-dot">
									<span className="dot dot-1"></span>
									<span className="dot dot-2"></span>
									<span className="dot dot-3"></span>
								</span>
							</div>
						</div>
						<div className="col-md-4 mb-4">
							<h3 className="subtitle mb-2">Misc</h3>
							<h6>Github</h6>
							<h6>Terminal</h6>
							<h6>Sequel Pro</h6>
							<h6>App Store Connect</h6>
							<h6>Play Store Console</h6>
							<h6>Huawei App Gallery Connect</h6>
							<div className="tools-icon position-relative ">
								<span className="jumping-dot">
									<span className="dot dot-1"></span>
									<span className="dot dot-2"></span>
									<span className="dot dot-3"></span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default About;
