import React from "react";

const PortfolioButton = ({ item, ...props }) => {
  const buttonType = item ? types[item.type] + " p-3 btn-main col-md-auto mr-4" : null;

  return(
    item ?
    <a
      href={item.src}
      className={buttonType}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <i className={item.icon} /> {item.text}
    </a> 
    : null
  );
};

const types = {
  'red': "bg-main",
  'blue': "bg-bh",
};

export default PortfolioButton;
