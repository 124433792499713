import React, { useState } from "react";
import { Row, Nav } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
// import "./styles.css";

import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import PortfolioModal from "./components/PortfolioModal.js";
import PortfolioCard from "./components/PortfolioCard.js";
// import Database from "./Database.js";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const getPortfolioLengthByTag = tag => {
  return Database.filter(Database => Database.tag.includes(tag)).length;
};

const App = () => {
  const [filter, setFilter] = useState("all");

  const [modalItem, setModalItem] = useState(null);

  const handleShowModal = id => {
    setModalItem(id ? Database.find(x => x.id === id) : null);
  };

  return (
    <div className="app">
      <Helmet>
        <title>Tan Wan Jern's Portfolio</title>
        <meta name="description" content="A designer & developer specializing in website and mobile app with more than 3 years experience. Solid experience with html, css, javascript, php, mysql, swift and java." />

        <meta property="og:title" content="Tan Wan Jern's Portfolio"/>
        <meta property="og:description" content="A designer & developer specializing in website and mobile app with more than 3 years experience. Solid experience with html, css, javascript, php, mysql, swift and java."/>
        <meta property="og:site_name" content="tanwanjern"/>
        <meta property="og:url" content="https://tanwanjern.com/archived/2019"/>
        <meta property="og:image" content="../../../../assets-2019/logo.jpg"/>

        <meta name="twitter:title" content="Tan Wan Jern's Portfolio"/>
        <meta name="twitter:description" content="A designer & developer specializing in website and mobile app with more than 3 years experience. Solid experience with html, css, javascript, php, mysql, swift and java."/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content="../../../../assets-2019/logo.jpg"/>

        <link href="../../../../assets-2019/style.css" rel="stylesheet"/>
        <link rel="icon" type="image/png" href="../../../../assets-2019/logo.png"/>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800" rel="stylesheet"/>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossorigin="anonymous"/>
      </Helmet>
      <div className="bg-danger py-2 d-flex justify-items-center align-items-center">
        <div className="w-100 text-center">
          <Link to='/'>This is the old version (2019), Back to homepage <i className="fas fa-chevron-right small"></i></Link>
        </div>
      </div>
      <div className="container pb-5">
        <Header />
        <Nav variant="pills" defaultActiveKey="all" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="all" onClick={() => setFilter("all")}>
              All ({Database.length})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="web" onClick={() => setFilter("web")}>
              Website ({getPortfolioLengthByTag("web")})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="app" onClick={() => setFilter("app")}>
              Mobile App ({getPortfolioLengthByTag("app")})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="branding" onClick={() => setFilter("branding")}>
              Branding ({getPortfolioLengthByTag("branding")})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="live" onClick={() => setFilter("live")}>
              Live ({getPortfolioLengthByTag("live")})
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Row>
          {Database.filter(Database => Database.tag.includes(filter)).map(
            (item, index) => {
              return (
                <PortfolioCard
                  className="mb-4"
                  key={index}
                  item={item}
                  modal={() => handleShowModal(item.id)}
                />
              );
            }
          )}
        </Row>
        <Footer />
      </div>
      <PortfolioModal
        visible={!!modalItem}
        handleClose={() => handleShowModal(false)}
        item={modalItem}
      />
    </div>
  );
};

export default App;

const Database = [
  {
    id: "29",
    title: "Lovers Eyes.",
    tag: ["all", "branding", "live"],
    job: "Logo design",
    type: "Branding",
    status: "live",
    description: "",
    links: [
      {
        src: "https://www.instagram.com/my_lovers_eyes/",
        type: "red",
        text: "Visit Instagram",
        icon: "fab fa-instagram"
      },
      {
        src: "https://www.behance.net/gallery/89927159/My-Lovers-Eyes-Logo-Design",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/loverseyes/thumb.png",
    images: [
      {
        src: "../../../../assets-2019/loverseyes/thumb.png",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/loverseyes/1.png",
        caption: "Behance"
      }
    ]
  },
  {
    id: "28",
    title: "Ladune",
    tag: ["all", "web", "live"],
    job: "Web design, Front-end development",
    type: "Website",
    status: "live",
    description: "",
    links: [
      {
        src: "https://www.ladunebeauty.com/index.php",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/ladune/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/ladune/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/ladune/1.jpg",
        caption: "About Us"
      },
      {
        src: "../../../../assets-2019/ladune/2.jpg",
        caption: "Product Detail"
      },
      {
        src: "../../../../assets-2019/ladune/3.jpg",
        caption: "Register"
      }
    ]
  },
  {
    id: "27",
    title: "Prestige Auto",
    tag: ["all", "web"],
    job: "Web design",
    type: "Website",
    status: "offline",
    description: "Prestige Auto is a car dealer website",
    links: [],
    preview: "../../../../assets-2019/prestigeauto/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/prestigeauto/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/prestigeauto/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/prestigeauto/2.jpg",
        caption: "Stock List"
      },
      {
        src: "../../../../assets-2019/prestigeauto/3.jpg",
        caption: "Car Detail"
      },
      {
        src: "../../../../assets-2019/prestigeauto/4.jpg",
        caption: "User Login"
      },
      {
        src: "../../../../assets-2019/prestigeauto/5.jpg",
        caption: "FAQ"
      },
    ]
  },
  {
    id: "26",
    title: "Woodbury",
    tag: ["all", "web", "live"],
    job: "Web design, Front-end development",
    type: "Website",
    status: "live",
    description: "",
    links: [
      {
        src: "http://woodbury.my/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/woodbury/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/woodbury/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/woodbury/1.jpg",
        caption: "Enquiry Form"
      },
      {
        src: "../../../../assets-2019/woodbury/2.jpg",
        caption: "Homepage"
      }
    ]
  },
  {
    id: "25",
    title: "Giant",
    tag: ["all", "web"],
    job: "Web design",
    type: "Website",
    status: "backend",
    description: "This is an e-learning Platform for Giant's employee.",
    links: [],
    preview: "../../../../assets-2019/giant/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/giant/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/giant/1.jpg",
        caption: "Learning Modules"
      },
      {
        src: "../../../../assets-2019/giant/2.jpg",
        caption: "Course Video"
      },
      {
        src: "../../../../assets-2019/giant/3.jpg",
        caption: "Member List"
      },
      {
        src: "../../../../assets-2019/giant/4.jpg",
        caption: "Home (Mobile Responsive)"
      },
      {
        src: "../../../../assets-2019/giant/5.jpg",
        caption: "Side Navigation (Mobile Responsive)"
      }
    ]
  },
  {
    id: "24",
    title: "RebateMango",
    tag: ["all", "web", "live"],
    job: "Web design",
    type: "Website",
    status: "live",
    description: "RebateMango is a Loyalty Reward Platform that consist of an online shopping website that seeks to provide significant value and savings to members in the form of cashback, air miles as well as loyalty points.",
    links: [
      {
        src: "https://www.rebatemango.my/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/rebatemango/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/rebatemango/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/rebatemango/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/rebatemango/2.jpg",
        caption: "How It Work"
      },
      {
        src: "../../../../assets-2019/rebatemango/3.jpg",
        caption: "Stock List"
      },
      {
        src: "../../../../assets-2019/rebatemango/4.jpg",
        caption: "User Dashboard"
      },
      {
        src: "../../../../assets-2019/rebatemango/5.jpg",
        caption: "Withdraw Money"
      }
    ]
  },
  {
    id: "23",
    title: "VRSG",
    tag: ["all", "web"],
    job: "Web design",
    type: "Website",
    status: "offline",
    description: "VRSG is a virtual run event website",
    links: [],
    preview: "../../../../assets-2019/vrsg/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/vrsg/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/vrsg/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/vrsg/2.jpg",
        caption: "Event Detail"
      },
      {
        src: "../../../../assets-2019/vrsg/3.jpg",
        caption: "Side Navigation"
      },
      {
        src: "../../../../assets-2019/vrsg/4.jpg",
        caption: "User Registration"
      }
    ]
  },
  {
    id: "22",
    title: "Foodi",
    tag: ["all", "web"],
    job: "Web design",
    type: "Website",
    status: "offline",
    description: "",
    links: [],
    preview: "../../../../assets-2019/foodi/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/foodi/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/foodi/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/foodi/2.jpg",
        caption: "Restaurant Detail"
      },
      {
        src: "../../../../assets-2019/foodi/3.jpg",
        caption: "Crowdfunding List"
      },
      {
        src: "../../../../assets-2019/foodi/4.jpg",
        caption: "User Profile"
      },
      {
        src: "../../../../assets-2019/foodi/5.jpg",
        caption: "Blog"
      }
    ]
  },
  {
    id: "21",
    title: "WarrantySmart",
    tag: ["all", "web", "live"],
    job: "Web design, Front-end development",
    type: "Website",
    status: "live",
    description: "Warranty Smart is the extended vehicle warranty provider in Malaysia.",
    links: [
      {
        src: "http://www.warrantysmart.com.my/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://www.behance.net/gallery/53314529/Warrantysmart-Car-Warranty-Website-Design",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/warrantysmart/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/warrantysmart/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/warrantysmart/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/warrantysmart/2.jpg",
        caption: "About"
      },
      {
        src: "../../../../assets-2019/warrantysmart/3.jpg",
        caption: "Package"
      }
    ]
  },
  {
    id: "20",
    title: "AnyRace",
    tag: ["all", "web", "branding", "live"],
    job: "Web design, Front-end development, Logo design",
    type: "Website",
    status: "live",
    description: "AnyRace is a virtual run event website.",
    links: [
      {
        src: "https://anyrace.net/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://www.behance.net/gallery/53317841/Anyrace-Virtual-Run-Logo",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/anyrace/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/anyrace/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/anyrace/1.jpg",
        caption: "Behance Layout"
      }
    ]
  },
  {
    id: "19",
    title: "Abbo",
    tag: ["all", "web", "live"],
    job: "Web design, Front-end development",
    type: "Website",
    status: "live",
    description: "Abbo is a wedding planning platform in Taiwan.",
    links: [
      {
        src: "http://abbo.com.tw/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://www.behance.net/gallery/69627937/Abbo-Wedding-Planning-Website-Design",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/abbo/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/abbo/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/abbo/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/abbo/2.jpg",
        caption: "Merchant Detail"
      },
      {
        src: "../../../../assets-2019/abbo/3.jpg",
        caption: "User Dashboard"
      },
      {
        src: "../../../../assets-2019/abbo/4.jpg",
        caption: "Checkout"
      },
      {
        src: "../../../../assets-2019/abbo/5.jpg",
        caption: "Pop up"
      }
    ]
  },
  {
    id: "18",
    title: "ARA: Golden Village",
    tag: ["all", "app", "live"],
    job: "App design, App development",
    type: "Mobile Application",
    status: "live",
    description: "ARA is a cinema workforce rostering app for golden village cinema.",
    links: [
      {
        src: "https://play.google.com/store/apps/details?id=my.com.xover.gvrostering",
        type: "red",
        text: "View On Google Play",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://www.behance.net/gallery/68039555/ARA-Cinema-Workforce-Rostering-App",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/ara/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/ara/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/ara/1.jpg",
        caption: "Homepage"
      }
    ]
  },
  {
    id: "17",
    title: "RewardMama",
    tag: ["all", "app", "live"],
    job: "App design, Android development",
    type: "Mobile Application",
    status: "live",
    description: "ARA is a cinema workforce rostering app for golden village cinema.",
    links: [
      {
        src: "https://play.google.com/store/apps/details?id=com.rewardmama",
        type: "red",
        text: "View On Play Store",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/rewardmama/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/rewardmama/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/rewardmama/1.jpg",
        caption: "Behance Layout"
      }
    ]
  },
  {
    id: "16",
    title: "Sweat",
    tag: ["all", "app"],
    job: "App design, App development",
    type: "Mobile Application",
    status: "offline",
    description: "Sweat is an app that allow everyone to find gym trainers in Malaysia.",
    links: [],
    preview: "../../../../assets-2019/sweat/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/sweat/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/sweat/1.jpg",
        caption: "Get Started/Login"
      },
      {
        src: "../../../../assets-2019/sweat/2.jpg",
        caption: "Dashboard/Profile"
      },
      {
        src: "../../../../assets-2019/sweat/3.jpg",
        caption: "Find Trainer/Recommended Trainer"
      },
      {
        src: "../../../../assets-2019/sweat/4.jpg",
        caption: "Trainer Profile"
      }
    ]
  },
  {
    id: "15",
    title: "Club Booking App",
    tag: ["all", "app"],
    job: "App design, App development",
    type: "Mobile Application",
    status: "offline",
    description: "This app allow club agent to book the seat and buy event ticket for their client..",
    links: [],
    preview: "../../../../assets-2019/club/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/club/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/club/1.jpg",
        caption: "Restaurant/Event Detail"
      },
      {
        src: "../../../../assets-2019/club/2.jpg",
        caption: "Booking"
      },
      {
        src: "../../../../assets-2019/club/3.jpg",
        caption: "Booking Detail/Edit Profile"
      },
      {
        src: "../../../../assets-2019/club/3.jpg",
        caption: "User Profile/Register"
      }
    ]
  },
  {
    id: "14",
    title: "Monepoly",
    tag: ["all", "app"],
    job: "App design, App development",
    type: "Mobile Application",
    status: "offline",
    description: "Monepoly is a business game application.",
    links: [],
    preview: "../../../../assets-2019/monepoly/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/monepoly/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/monepoly/1.jpg",
        caption: "Business Overview/Add Business"
      },
      {
        src: "../../../../assets-2019/monepoly/2.jpg",
        caption: "My Profile/Friend List"
      },
      {
        src: "../../../../assets-2019/monepoly/3.jpg",
        caption: "Booking Detail/Edit Profile"
      }
    ]
  },
  {
    id: "13",
    title: "Dotastat",
    tag: ["all", "app"],
    job: "App design, App development",
    type: "Mobile Application",
    status: "offline",
    description: "Dotastat is an application that allow dota 2 gamer to check their match statistic.",
    links: [],
    preview: "../../../../assets-2019/dotastat/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/dotastat/thumb.jpg",
        caption: "Dashboard/Match Detail"
      },
      {
        src: "../../../../assets-2019/dotastat/1.jpg",
        caption: "Dashboard/Match Player Detail"
      }
    ]
  },
  {
    id: "12",
    title: "Finnport",
    tag: ["all", "app", "live"],
    job: "App design, App development",
    type: "Mobile Application",
    status: "live",
    description: "Finnport is an application that allow user to record their daily expense & Income.",
    links: [
      {
        src: "https://play.google.com/store/apps/details?id=xover.finncitiapp",
        type: "red",
        text: "View On Play Store",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://itunes.apple.com/my/app/finnport/id1460545673?mt=8&ign-mpt=uo%3D4",
        type: "red",
        text: "View On App Store",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/finnciti/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/finnciti/thumb.jpg",
        caption: "Overview"
      }
    ]
  },
  {
    id: "11",
    title: "BabyKingdom",
    tag: ["all", "web"],
    job: "Web design",
    type: "Website",
    status: "offline",
    description: "Baby Kingdom is one of the most prestigious kindergarten and baby care center in Malaysia.",
    links: [],
    preview: "../../../../assets-2019/babykingdom/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/babykingdom/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/babykingdom/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/babykingdom/2.jpg",
        caption: "Mobile Responsive"
      }
    ]
  },
  {
    id: "10",
    title: "Sport Event",
    tag: ["all", "web", "live"],
    job: "Web design, Web development",
    type: "Website",
    status: "live",
    description: "Sport Event is an online sport event registration and payment platform.",
    links: [
      {
        src: "https://www.sportsevents.asia/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/sportevent/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/sportevent/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/sportevent/1.jpg",
        caption: "Event List"
      },
      {
        src: "../../../../assets-2019/sportevent/2.jpg",
        caption: "Event Detail"
      },
      {
        src: "../../../../assets-2019/sportevent/3.jpg",
        caption: "Event Registration Form"
      },
      {
        src: "../../../../assets-2019/sportevent/4.jpg",
        caption: "Event Registration Form"
      },
      {
        src: "../../../../assets-2019/sportevent/5.jpg",
        caption: "Event Registration Form"
      },
    ]
  },
  {
    id: "9",
    title: "Tapao",
    tag: ["all", "web", "live"],
    job: "Web Design, Front-end development",
    type: "Website",
    status: "live",
    description: "",
    links: [
      {
        src: "https://tapao4u.com/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://www.behance.net/gallery/53785267/Ta-Pao-Packaging-Website-Design",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/tapao/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/tapao/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/tapao/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/tapao/2.jpg",
        caption: "Product Detail"
      },
      {
        src: "../../../../assets-2019/tapao/3.jpg",
        caption: "Shopping Cart"
      },
      {
        src: "../../../../assets-2019/tapao/4.jpg",
        caption: "Checkout"
      },
      {
        src: "../../../../assets-2019/tapao/5.jpg",
        caption: "Product Customisation"
      },
    ]
  },
  {
    id: "8",
    title: "Malaysia Metabolic Society",
    tag: ["all", "web", "live"],
    job: "Web Design, Web Development",
    type: "Website",
    status: "live",
    description: "Malaysia Metabolic Society (MMS) is a non-profit organizatione established by a group of parents and medical profession to help patients who suffered from rare diseases / disorders, publicly known as inborn error of metabolism (IEM).",
    links: [
      {
        src: "http://mms.org.my/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/mms/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/mms/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/mms/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/mms/2.jpg",
        caption: "About Us"
      },
      {
        src: "../../../../assets-2019/mms/3.jpg",
        caption: "Contact"
      }
    ]
  },
  {
    id: "7",
    title: "Drjuice2u",
    tag: ["all", "web", "app", "live"],
    job: "Web & app design, Front-end development",
    type: "Website, Mobile Application",
    status: "live",
    description: "Drjuice2u is a e-commerce website for selling juice.",
    links: [
      {
        src: "http://www.drjuice2u.com/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      },
      {
        src: "https://www.behance.net/gallery/53315303/DrJuice-Mobile-Application-UI-Design",
        type: "blue",
        text: "View On Behance",
        icon: "fab fa-behance"
      }
    ],
    preview: "../../../../assets-2019/drjuice/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/drjuice/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/drjuice/1.jpg",
        caption: "Behance Layout"
      },
      {
        src: "../../../../assets-2019/drjuice/2.jpg",
        caption: "Website"
      }
    ]
  },
  {
    id: "6",
    title: "Promeritus",
    tag: ["all", "web"],
    job: "Web Design",
    type: "Website",
    status: "offline",
    description: "Promeritus is an e-learning platform.",
    links: [],
    preview: "../../../../assets-2019/promeritus/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/promeritus/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/promeritus/1.jpg",
        caption: "Homepage"
      }
    ]
  },
  {
    id: "5",
    title: "Ampersand",
    tag: ["all", "web", "live"],
    job: "Web Design, Front-end Development",
    type: "Website",
    status: "live",
    description: "Ampersand Ventures is a diversified technology solutions provider for mobile and enterprise solutions.",
    links: [
      {
        src: "http://ampersand.com.my/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/ampersand/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/ampersand/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/ampersand/1.jpg",
        caption: "Homepage"
      }
    ]
  },
  {
    id: "4",
    title: "TryItGame",
    tag: ["all", "web", "live"],
    job: "Web Design, Front-end Development",
    type: "Website",
    status: "live",
    description: "TryItGame is an game marketplace website.",
    links: [
      {
        src: "http://tryitgame.com/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/tryitgame/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/tryitgame/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/tryitgame/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/tryitgame/2.jpg",
        caption: "Game Detail"
      },
      {
        src: "../../../../assets-2019/tryitgame/3.jpg",
        caption: "About Us"
      }
    ]
  },
  {
    id: "3",
    title: "PRamlee",
    tag: ["all", "web", "live"],
    job: "Web Design",
    type: "Website",
    status: "live",
    description: "",
    links: [
      {
        src: "http://www.pramlee.com.my/",
        type: "red",
        text: "Visit The Website",
        icon: "fas fa-external-link-alt"
      }
    ],
    preview: "../../../../assets-2019/pramlee/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/pramlee/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/pramlee/1.jpg",
        caption: "Homepage"
      }
    ]
  },
  {
    id: "2",
    title: "Palms",
    tag: ["all", "web"],
    job: "Web Design, Front-end development",
    type: "Website",
    status: "offline",
    description: "",
    links: [],
    preview: "../../../../assets-2019/palm/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/palm/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/palm/1.jpg",
        caption: "Homepage"
      },
      {
        src: "../../../../assets-2019/palm/2.jpg",
        caption: "Product Detail"
      },
      {
        src: "../../../../assets-2019/palm/3.jpg",
        caption: "Checkout"
      },
      {
        src: "../../../../assets-2019/palm/4.jpg",
        caption: "Login"
      }
    ]
  },
  {
    id: "1",
    title: "Cyberview",
    tag: ["all", "web"],
    job: "Web Design",
    type: "Website",
    status: "backend",
    description: "",
    links: [],
    preview: "../../../../assets-2019/cyberview/thumb.jpg",
    images: [
      {
        src: "../../../../assets-2019/cyberview/thumb.jpg",
        caption: "Overview"
      },
      {
        src: "../../../../assets-2019/cyberview/1.jpg",
        caption: "Layout 1"
      },
      {
        src: "../../../../assets-2019/cyberview/2.jpg",
        caption: "Layout 2"
      },
      {
        src: "../../../../assets-2019/cyberview/3.jpg",
        caption: "Layout 3"
      }
    ]
  }
];