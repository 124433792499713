import React from "react";
import { Col, Button } from "react-bootstrap";

const PortfolioCard = ({ item, modal, ...props }) => {

  return (
    item ?
    <Col lg="4" md="6" xs="12" {...props}>
      <div className="bg-dark h-100 position-relative">
        <img src={item.preview} className="w-100" alt={item.title} />
        <div className="p-4 text-left text-white">
          <h4 className="font-weight-bold">{item.title}</h4>
          <p className="text-light">{item.job}</p>
          <StatusBadge tag={item.status} key={item.id}></StatusBadge>
        </div>
        <Button className="btn-absolute" onClick={modal} />
      </div>
    </Col>
    : null
  );
};

const types = {
  'offline': "badge-secondary",
  'backend': "badge-warning",
  'success': "badge-success",
};

const StatusBadge = ({tag}) => {
  const badgeType = "badge badge-pill text-capitalize " + (types[tag] || types["success"]);

  if(tag === 'offline'){
    return (<p className={badgeType}>{tag}</p>);
  }
  
  else if(tag === 'backend'){
    return (<p className={badgeType}>{tag}</p>);
  }

  else{
    return (<p className={badgeType}>{tag}</p>);
  }
};

export default PortfolioCard;
