import React from "react";
import { Modal } from "react-bootstrap";

import PortfolioCarousel from "./PortfolioCarousel";
import PortfolioButton from "./PortfolioButton";
import * as styles from "../modal.module.css"

const PortfolioModal = ({ visible, handleClose, item, ...props }) => {
  if (!item) {
    return null;
  }

  return (
    item ?
    <Modal show={visible} onHide={handleClose} {...props} className={styles.modalElement}>
      <Modal.Body className="text-dark">
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
        
        <h3 className="">{item.title}</h3>

        { 
          item.title ? 
            <p className="text-secondary">{item.description}</p>
          : null
        }

        <div className="row no-gutters">
          <p className="mr-4">
            <i className="fas fa-tag" /> {item.type}
          </p>
          <p className="">
            <i className="fas fa-briefcase" /> {item.job}
          </p>
        </div>

        { 
          item.links.length > 0 ? 
            <div className="mt-3 mb-4 row no-gutters">
              {item.links.map((link, index) => {
                return <PortfolioButton item={link} key={index} />;
              })}
            </div>
          : ""
        }
        

        <PortfolioCarousel item={item} key={item.id} />
      </Modal.Body>
    </Modal>
    :
    null
  );
};

export default PortfolioModal;
