import { Link } from "gatsby"
import * as React from "react"

import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import About from "../components/About"
import Divider from "../components/Divider"
import Work from "../components/Work"


const IndexPage = () => {
  return (
	<main>
		<Helmet>
			<meta charSet="utf-8" />

			<title>Tan Wan Jern | Product Designer & Frontend Engineer | Creative Technologist | UI/UX Design</title>
			<meta name="description" content="Product designer & frontend engineer with 6+ years of experience in a variety of roles, including UI/UX design, frontend development, mobile app development and project management." />
        
			<meta property="og:title" content="Tan Wan Jern | Product Designer & Frontend Engineer | Creative Technologist | UI/UX"/>
			<meta property="og:description" content="Product designer & frontend engineer with 6+ years of experience in a variety of roles, including UI/UX design, frontend development, mobile app development and project management."/>
			<meta property="og:site_name" content="tanwanjern"/>
			<meta property="og:url" content="https://tanwanjern.com/"/>
			<meta property="og:image" content="https://tanwanjern.com/assets-2021/tanwanjern-og.png"/>

			<meta name="twitter:title" content="Tan Wan Jern | Product Designer & Frontend Engineer | Creative Technologist | UI/UX"/>
			<meta name="twitter:description" content="Product designer & frontend engineer with 6+ years of experience in a variety of roles, including UI/UX design, frontend development, mobile app development and project management."/>
			<meta name="twitter:card" content="summary_large_image"/>
			<meta name="twitter:image" content="https://tanwanjern.com/assets-2021/tanwanjern-og.png"></meta>

			<link rel="icon" type="image/png" href="../../../../assets-2021/favicon.png"/>
			<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossorigin="anonymous"/>
		</Helmet>
		<div className="container">
			<div className="brand-container">
				<p className="brand-name">
					Tan Wan Jern <br/>
					<span className="brand-title">Product Designer & Frontend Engineer</span>
				</p>
			</div>

			<div className="row justify-content-between align-items-center billboard">
				<div className="col-md mb-5 mb-md-4">
					<h1 className="headline">
						Creating {' '}
						<span className="gradient-text">meaningful and diverse</span> {' '}
						projects for {' '}
						<span className="underline-text">every levels of ambition.</span>
					</h1>
				</div>
				<div className="col-md-4 col-lg-3 mb-4">
					<div className="profile-image-container">
						<StaticImage src="../images/assets-2021/tanwanjern.png" className="w-100 h-100 rounded" alt="Lester Tan Wan Jern" draggable="false" />
					</div>
				</div>
			</div>

			<div className="py-4">
				<Work/>
			</div>
		</div>

		<Divider/>

		<About/>

		<Divider/>

		<div className="container">
			<div className="py-5">
				<h4 className="section-title mb-3">Quick Links</h4>
				<div className="row row-sm">
					<div className="col-lg-4 mb-3">
						<div className="quick-link bg-ql-1">
							<h4>
								2019 Website
							</h4>
							<p>
								The previous version of my portfolio website, all the work was completed between 2016 and 2019.
							</p>
							<Link to='/archived/2019'>Visit now</Link>

							<img src={'../assets-2021/ql-1.png'} className="visual" alt={"2019 portfolio website"} draggable="false"/>
						</div>
					</div>
					<div className="col-lg-4 mb-3">
						<div className="quick-link bg-ql-2">
							<h4>
								Complete portfolio/resume
							</h4>
							<p>
								Looking for a full-fledged portfolio or resume? Feel free to contact me by clicking the button below.
							</p>
							<a href="mailto:twanjern@gmail.com">Request via email</a>
							<img src={'../assets-2021/ql-2.png'} className="visual" alt={"Complete portfolio/resume"} draggable="false"/>
						</div>
					</div>
					<div className="col-lg-4 mb-3">
						<div className="quick-link bg-ql-3">
							<h4>
								Contact &#38; Connect
							</h4>
							<p>
								Please feel free to contact me via email or on different social media platforms.
							</p>
							<div className="my-3 mt-md-5 pt-md-3">
								<div className="py-2 px-3 mb-2 contact-link">
									<a href="mailto:twanjern@gmail.com">
										<i className="fas fa-envelope"></i> Email
									</a>
								</div>
								<div className="py-2 px-3 mb-2 contact-link">
									<a href="https://www.linkedin.com/in/tanwanjern/" rel="noreferrer" target="_blank">
										<i className="fab fa-linkedin-in"></i> LinkedIn
									</a>
								</div>
								<div className="py-2 px-3 mb-2 contact-link">
									<a href="https://www.behance.net/tanwanjern?tracking_source=search_projects_recommended%7Ctanwanjern" rel="noreferrer" target="_blank">
										<i className="fab fa-behance"></i> Behance
									</a>
								</div>
								<div className="py-2 px-3 mb-2 contact-link">
									<a href="https://github.com/tanwanjern?tab=repositories" rel="noreferrer" target="_blank">
										<i className="fab fa-github"></i> Github
									</a>
								</div>
								<div className="py-2 px-3 mb-2 contact-link">
									<a href="https://twitter.com/lestertanwj" rel="noreferrer" target="_blank">
										<i className="fab fa-twitter"></i> Twitter
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
			
		<div className="bg-light">
			<div className="container">
				<div className="pt-4 pb-3">
					<p className="copyright mb-2">Copyright © tanwanjern 2022</p>
				</div>
			</div>
		</div>

	</main>
  )
}

export default IndexPage
